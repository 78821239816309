.account-config-form{
  .table-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    line-height: 36px;
    padding: 20px 10px;
    &.block{
      display: block;
    }
    .MuiTextField-root{
      margin: 0;
      vertical-align: middle;
      .MuiFormHelperText-root{
        position: absolute;
        top: 38px;
        line-height: 16px;
      }
    }
    .info-icon{
      font-size: 16px;
      margin-left: 5px;
      opacity: .4;
      transition: .3s;
    }
    &:hover{
      .info-icon{
        opacity: .9;
      }
    }
  }
}