.status-reason-txt {
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: normal !important;
}

.popover {
  padding: 4px;
  background-color: #dad72a;
  color: white
}
