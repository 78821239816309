.stats-table{
    .stats-collapsible-row{
      td, th {
        font-size: 14px;
        font-weight: bold;
        &.green{
          font-size: 20px;
          line-height: 24px;
        }
      }
      th{
        font-size: 16px;
        font-weight: bold;
      }
    }
    .stats-collapsible-row-child{
      >td,
      >th {
        border-bottom: none;
      }
      .stats-collapsible-row{
        td, th {
          font-size: 14px;
          background: #fafafa;
          &.green{
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
    .green{
      color: #63a463;
    }
    .yellow{
      color: #d3b071;
    }
    .blue{
      color: #4889ff;
    }
}