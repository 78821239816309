.bundles-table-container {
  td {
    font-size: 14px;
  }

  th {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }

  .bundle-status-tag {
    opacity: 0.9;
  }
}
