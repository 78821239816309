.tx-link{
   .copy-icon{
        font-size: 14px;
        opacity: 0.5;
        position: relative;
        top: 2px;
        transition: 0.2s;
        display: inline-block;
        width: 20px;
        margin-left: 10px;
        &:hover{
            opacity: 0.9;
        }
    } 
}