.bundle-card{
    th{
        line-height: normal;
    }
    .bundle-status-tag{
        margin-top: -2px;
    }
}

.popover-fail {
    padding: 4px;
    background-color: #c66262;
    color: white
}
